import React from "react"
import OrderSuccess from "../../../components/Order/OrderSuccess"
import PrivateRoute from "../../../components/auth/PrivateRoute/loadable"
import ProfileRoute from "../../../components/auth/ProfileRoute"

const SuccessPage = props => (
  <PrivateRoute url={props.location.href}>
    <ProfileRoute>
      <OrderSuccess url={props.location.href} />
    </ProfileRoute>
  </PrivateRoute>
)

export default SuccessPage
